
	frappe.templates['form_sidebar'] = `<a class="sr-only sr-only-focusable btn btn-primary-light my-2 skip-link" tabindex="0" href="#">
	{{ __("Navigate to main content") }}
</a>
<div class="sidebar-section user-actions hidden"></div>
<div class="sidebar-section sidebar-image-section hide">
	<div class="sidebar-image-wrapper">
		<img class="sidebar-image">
		<div class="sidebar-standard-image">
			<div class="standard-image"></div>
		</div>
		{% if can_write %}
		<div class="sidebar-image-actions">
			<div class="dropdown">
				<a href="#" class="dropdown-toggle" data-toggle="dropdown">{{ __("Change") }}</a>
				<div class="dropdown-menu" role="menu">
					<a class="dropdown-item sidebar-image-change">{{ __("Upload") }}</a>
					<a class="dropdown-item sidebar-image-remove">{{ __("Remove") }}</a>
				</div>
			</div>
		</div>
		{% endif %}
	</div>
</div>
{% if frm.meta.beta %}
<div class="sidebar-section">
	<p><label class="indicator-pill yellow" title="{{ __("This feature is brand new and still experimental") }}">{{ __("Experimental") }}</label></p>
	<div><a class="small text-muted" href="https://gitlab.com/dokos/dokos/issues/new" target="_blank">
		{{ __("Report bug") }}</a></div>

</div>
{% endif %}
<div class="sidebar-section sidebar-rating hide">
	<div style="position: relative;">
		<a class="strong badge-hover">
			<span>{%= __("Feedback") %}</span>
		</a>
	</div>
	<div class="rating-icons"></div>
</div>
<div class="sidebar-section hidden">
	{% if (frappe.help.has_help(doctype)) { %}
	<div>
		<a class="help-link list-link" data-doctype="{{ doctype }}">{{ __("Help") }}</a>
	</div>
	{% } %}
</div>
<div class="sidebar-section form-assignments">
	<div>
		<span class="form-sidebar-items">
			<span class="sidebar-label ellipsis add-assignment-label">
				{{ frappe.utils.icon("es-line-add-people", "sm") }}
				<span class="ellipsis">{%= __("Assigned To") %}</span>
			</span>
			<button class="add-assignment-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
		<div class="assignments"></div>
	</div>
</div>
<div class="sidebar-section form-attachments">
	<div class="attachments-actions">
		<span class="form-sidebar-items">
			<span class="sidebar-label ellipsis attachments-label">
				{{ frappe.utils.icon("es-line-attachment", "sm") }}
				<a class="pill-label ellipsis explore-link" title="{%= __('Explore Files') %}">
					{%= __("Attachments") %}
				</a>
			</span>
			<button class="add-attachment-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
	</div>
	<a class="show-all-btn hidden" href="">
		{{ frappe.utils.icon("es-line-preview", "sm") }}
		<span class="pill-label ellipsis">
			{%= __("Show All") %}
		</span>
	</a>
</div>
<div class="sidebar-section form-reviews">
	<div class="reviews">
		<span class="form-sidebar-items">
			<span class="sidebar-label ellipsis">
				{{ frappe.utils.icon("es-line-star", "sm") }}
				<span class="ellipsis">{%= __("Reviews") %}</span>
			</span>
			<button class="add-review-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
	</div>
</div>
<div class="sidebar-section form-tags">
	<div>
		<span class="form-sidebar-items">
			<div class="sidebar-label ellipsis">
				{{ frappe.utils.icon("es-line-tag", "sm") }}
				<span class="tags-label ellipsis">{%= __("Tags") %}</span>
			</div>
		</span>
	</div>
</div>
<div class="sidebar-section form-shared">
	<div>
		<span class="form-sidebar-items">
			<span class="sidebar-label ellipsis share-label">
				{{ frappe.utils.icon("es-line-people", "sm") }}
				<span class="ellipsis">{%= __("Share") %}</span>
			</span>
			<button class="share-doc-btn btn btn-link icon-btn">
				<svg class="es-icon icon-sm"><use href="#es-line-add"></use></svg>
			</button>
		</span>
		<div class="shares"></div>
	</div>
</div>
<div class="sidebar-section followed-by-section hidden">
	<div class="sidebar-label ellipsis followed-by-label">
		{{ frappe.utils.icon("es-line-preview", "sm") }}
		{%= __("Followed by") %}
	</div>
	<div class="followed-by"></div>
	<div class="document-follow">
		<a class="badge-hover follow-document-link hidden">
			{%= __("Follow") %}
		</a>
		<a class="badge-hover unfollow-document-link hidden">
			{%= __("Unfollow") %}
		</a>
	</div>
</div>
<div class="sidebar-section hidden">
	<a><li class="indicator blue auto-repeat-status" style="display: none;"></li></a>
</div>
<div class="sidebar-section text-muted border-top mt-3 pt-3">
	<div class="pageview-count hidden"></div>
	<div class="created-modified-section">
	</div>
</div>
{% if(frappe.get_form_sidebar_extension) { %}
	{{ frappe.get_form_sidebar_extension() }}
{% } %}
`;
